@font-face {
  font-family: Poppins;
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
}
body {
  margin: 0;
  font-family: 'Poppins';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
